/** Tailwind Whitelisted classes
 * Tailwind classes can't be written dynamically instead they should be mapped
 * @see https://tailwindcss.com/docs/content-configuration#class-detection-in-depth
 */
import { TTailwindColorUtils } from '@unionco/alaris-app-types'

export const twBgColor: TTailwindColorUtils = {
  white: 'u-bg-white',
  'primary-300': 'u-bg-primary-300',
  'primary-500': 'u-bg-primary-500',
  'primary-700': 'u-bg-primary-700',
  'secondary-300': 'u-bg-secondary-300',
  'secondary-800': 'u-bg-secondary-800'
}

export const twTextColor: TTailwindColorUtils = {
  white: 'u-text-white',
  'primary-300': 'u-text-primary-300',
  'primary-500': 'u-text-primary-500',
  'secondary-800': 'u-text-secondary-800',
  'secondary-900': 'u-text-secondary-900'
}

export const twBorderColor: TTailwindColorUtils = {
  'primary-300': 'u-border-primary-300',
  'primary-500': 'u-border-primary-500',
  'secondary-400': 'u-border-secondary-400',
  'secondary-800': 'u-border-secondary-800'
}

export const hoverBgColor = [
  'hover:u-bg-white',
  'hover:u-bg-primary-300',
  'hover:u-bg-primary-500',
  'hover:u-bg-primary-700'
]

export const hoverTextColor = [
  'hover:u-text-white',
  'hover:u-text-primary-300',
  'hover:u-text-primary-500',
  'hover:u-text-primary-700'
]

export const hoverBorderColor = [
  'hover:u-text-border-300',
  'hover:u-text-border-500',
  'hover:u-text-border-700'
]
