/** Styled Button
 * Component for styling either button or anchor tags
 *
 * Props
 * Theme: Colors, written as color type names ex: primary, secondary, tertiary etc.
 * Style: Alters use of background & border combo
 *
 */
import { themedGhostStyle, themedOutlineStyle, themedSolidStyle } from './utils'
import { cx } from '@unionco/utils'

import Anchor, { IAnchorProps } from './Anchor'
import Button, { IButtonProps, TButtonTypes } from './Button'

export type TButtonTheme = 'primary' | 'secondary' | 'dark' | 'light'
export type TSolidButtonTheme = TButtonTheme | 'primary300'
export type TOutlineButtonTheme = TButtonTheme | 'secondaryAlt' | 'darkAlt'
export type TButtonStyle = 'solid' | 'outline' | 'ghost' | 'a'
export type TButtonAnimation = null | 'iconReveal'
export type TStyledButtonType = TButtonTypes | 'a'

export interface IButtonPattern {
  theme: TButtonTheme
  themeStyle: TButtonStyle
}

export type TStyledButtonProps = {
  // animation?: TButtonAnimation
  children: React.ReactNode | string
  className?: string
  large?: boolean
  roundingClass?: string
} & (
    | {
      themeStyle?: 'solid'
      theme?: TSolidButtonTheme
    }
    | {
      themeStyle: 'ghost'
      theme?: TButtonTheme
    }
    | {
      themeStyle: 'outline'
      theme?: TOutlineButtonTheme
    }
    | {
      themeStyle?: 'a'
      theme?: TButtonTheme
    }
  ) &
  (IAnchorProps | IButtonProps)

export const StyledButton: React.FC<TStyledButtonProps> = ({
  // animation = null,
  children,
  className,
  large,
  roundingClass,
  theme = 'primary',
  themeStyle = 'solid',
  type = 'button',
  ...props
}) => {
  const buttonStyle = (themeStyle: TButtonStyle, disabled: boolean) => {
    switch (themeStyle) {
      case 'solid':
        return themedSolidStyle(theme as TButtonTheme, disabled)
      case 'outline':
        return themedOutlineStyle(theme as TOutlineButtonTheme, disabled)
      case 'ghost':
        return themedGhostStyle(theme as TButtonTheme, disabled)
      default:
        return ''
    }
  }

  const disabled = props.disabled || false
  const sizeStyles = large ? 'u-py-300' : 'u-py-200'
  const rounding = roundingClass || 'u-rounded-full'

  const classes = cx(
    'u-group',
    buttonStyle(themeStyle, disabled),
    'u-uppercase u-font-bold u-px-400 u-text-2 u-tracking-[.25em] u-text-center',
    sizeStyles,
    rounding,
    className
  )

  switch (type) {
    case 'button':
    case 'reset':
    case 'submit':
      return (
        <Button className={classes} {...(props as IButtonProps)} type={type}>
          {children}
        </Button>
      )
    case 'a':
      return (
        <Anchor className={classes} {...(props as IAnchorProps)}>
          {children}
        </Anchor>
      )
    default:
      return <></>
  }
}

export default StyledButton
